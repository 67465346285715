import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { findIndex } from 'lodash'

import { Layout, PostCard, Pagination } from '../../components/ghost'
import { MetaData } from '../../components/common/meta'

/**
* Tag page (/tag/:slug)
*
* Loads all posts for the requested tag incl. pagination.
*
*/
const Tag = ({ data, location, pageContext }) => {
    // const tag = data.ghostTag
    const posts = (data.allGhostPost ? data.allGhostPost.edges : null)
    // let aboutCreated = false
    let nodeUrl = ``
    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="series"
            />
            <Layout>
                <div className="container">
                    <header className="tag-header">
                        <h1>{pageContext.tagTitle}</h1>
                        {pageContext.tagDescription ? <p>{pageContext.tagDescription}</p> : null }
                    </header>
                    <section className="post-feed">
                        { posts && posts.map(({ node }) => {
                            //set node.url = `/about/` if it's the latest post with about tag
                            // if ((findIndex(node.tags, tag => tag.name == `about`) >= 0) && (!aboutCreated)) {
                            //     nodeUrl = `/about/`
                            //     aboutCreated = true
                            // } else {
                            //     nodeUrl = `/${node.slug}/`
                            // }    
                            nodeUrl = `/${node.slug}/`
                            return (
                                // The tag below includes the markup for each post - components/common/PostCard.js
                                <PostCard key={node.id} post={node} nodeUrl={nodeUrl}/>
                            )
                        })}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default Tag

export const pageQuery = graphql`
    query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!, $authors: [String]!) {
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {tags: {elemMatch: {slug: {eq: $slug}}}, authors: {elemMatch: {slug: {in: $authors}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                    localImage {
                        childImageSharp {
                          fluid(maxWidth: 3720) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                    }
                    ...GhostPostFields
                }
            }
        }
    }
`
